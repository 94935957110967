@import '../../../styles/customMediaQueries.css';

.listingCardsMapVariant {
  padding: 0 0 96px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  justify-items: start;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: start;
  gap: 24px;
}

.listingCard {
  width: calc(100% - 48px); /* Adjust to fit within container */
  max-width: 800px; /* Set a max width for consistency */
  box-sizing: border-box; /* Ensure padding is included in width */
  display: contents;

  @media (--viewportLarge) {
    display: initial;
  }
}
