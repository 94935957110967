@import '../../../styles/customMediaQueries.css';

.labelPlain {
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.location {
  display: flex;
  align-items: center;
  font-size: 14px;

  & > svg {
    height: 16px;
  }
}
